<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Haydovchi malumotlari"
          ><b-card-text>
            <div v-if="getData.staff_description !== undefined">
              <div class="input__wrapper">
                <div class="input col-4">
                  <label>FIO</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getData.full__name"
                  />
                </div>

                <div class="input col-4">
                  <label>Manzil</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description
                        .pochta_address
                    "
                  />
                </div>

                <div class="input col-4">
                  <label>Pasport raqami</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description
                        .passport_number
                    "
                  />
                </div>
              </div>

              <div class="input__wrapper">
                <div class="input col-4">
                  <label>Telefon raqami</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description
                        .phone_number
                    "
                  />
                </div>

                <div class="input col-4">
                  <label>Pochta manzili</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description
                        .pochta_address
                    "
                  />
                </div>

                <div class="input col-4">
                  <label>INN</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description.inn
                    "
                  />
                </div>
              </div>
              <div class="input__wrapper">
                <div class="input col-4">
                  <label>INPS</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description.inps
                    "
                  />
                </div>

                <div class="input col-4">
                  <label>PINFL</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.staffpersonal_description.pinfl
                    "
                  />
                </div>

                <div class="input col-4">
                  <label>Ishga qabul kilingan sana</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getData.staff_description.employed_from | formatDate
                    "
                  />
                </div>
              </div>
            </div> </b-card-text
        ></b-tab>
        <b-tab title="Olib Borilgan Yuklar"
          ><b-card-text>
            <v-data-table
              :headers="driverrevenueHeader"
              :items="getData.driverrevenue_set"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getData.driverrevenue_set
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.zayavka`]="{ item }">
                <div
                  @click="zayavka(item.zayavka)"
                  style="
                    background-color: green;
                    text-align: center;
                    color: #fff;
                  "
                >
                  {{ item.zayavka }}
                </div>
              </template>
            </v-data-table>
            <div class="row justify-content-end mt-2">
              <div class="mr-4">
                <p
                  class="
                    text-dark
                    font-bold
                    label label-lg label-light-success label-inline
                  "
                  style="font-size: 15px"
                  v-if="getData.driverrevenue_set"
                >
                  Soni: {{ getData.driverrevenue_set.length }}
                </p>
              </div>
              <div>
                <p
                  class="
                    text-dark
                    font-bold
                    label label-lg label-light-success label-inline
                  "
                  style="font-size: 15px"
                >
                  Umumiy Summa: {{ totalSumm }}
                </p>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      driverrevenueHeader: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Sana',
          value: 'date'
        },
        {
          text: 'distance',
          value: 'distance'
        },
        {
          text: 'premie_sum',
          value: 'premie_sum'
        },
        {
          text: 'Haydovchi',
          value: 'driver'
        },
        {
          text: 'Truck',
          value: 'truck'
        },
        {
          text: 'Buyurtma',
          value: 'zayavka'
        }
      ],
      data2: [],
      totalSumm: 0
    }
  },
  beforeCreate() {
    this.$store.dispatch('getDriverDetails', this.$route.params.id)
  },
  watch: {
    getData: function (val) {
      if (val.driverrevenue_set) {
        let list = val.driverrevenue_set.map((x) => x.premie_sum)
        if (list) this.totalSumm = list.reduce((a, b) => a + b)
        val.driverrevenue_set.forEach(
          (element) =>
            (element.premie_sum = element.premie_sum.toLocaleString('zh-ZH'))
        )
        this.totalSumm = this.totalSumm.toLocaleString('zh-ZH')
      }
    }
  },
  computed: {
    getData() {
      const data = this.$store.state.requests.driverDetails
      return data
    }
  }
}
</script>

<style scoped>
.input__wrapper {
  display: flex;
}
.input label {
  display: block;
  font-size: 13px;
}
.input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.input:last-child {
  margin-right: 0;
}
.input-text {
  width: 100%;
  background-color: rgb(142 190 253 / 14%);
  padding: 1rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  font-size: 16px;
  border-radius: 3px;
}
</style>
